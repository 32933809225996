import { appRoutes } from './app.router';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {CommonModule, DatePipe} from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { AuthService } from './services';
import { UsersService } from './services/crud/users.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ErrorInterceptor } from './interceptors/errors.interceptor';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { RoleService } from './services/role.service';
import { AppDateAdapter, APP_DATE_FORMATS } from './providers/app-date-adapter';
import { CKEditorModule } from 'ckeditor4-angular';
import {ToastService} from 'angular-toastify';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MatOptionModule} from '@angular/material/core';
import { AddCardOrderDialogComponent } from './shared/add-card-order-dialog/add-card-order-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {MatTabsModule} from "@angular/material/tabs";

@NgModule({
  declarations: [
    AccessDeniedComponent,
    NotFoundComponent,
    AppComponent,
    AddCardOrderDialogComponent,

  ],
  entryComponents: [],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    MatMomentDateModule,
    CommonModule,
    HttpClientModule,
    CKEditorModule,
    RouterModule.forRoot(appRoutes),
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatCheckboxModule,
    FormsModule,
    MatInputModule,
    MatSnackBarModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatDialogModule,
    MatOptionModule,
    MatSelectModule,
    NgxChartsModule,
    MatTabsModule,
    //NgxStripeModule.forRoot(''), // MS 2022-03-03 remove stripe
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
    DatePipe,

    AuthService,
    UsersService,
    RoleService,
  ],
  exports: [
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
