import { Injectable } from '@angular/core';

/** Implement operations with user roles */
@Injectable()
export class RoleService {

    constructor() { }
    /**
     * Get user role from local or sesion storage
     * depending on checbox "Reminde me" on login
     */
    public getRoles(): string[] {
        const roles = localStorage.getItem('user_roles') || sessionStorage.getItem('user_roles');
        return JSON.parse(roles);
    }

    /**
     * Save user roles in brouser, clearing all roles before save
     *
     * @param roles roles whos need to store
     * @param remember in true save in local storage else session storage
     */
    public setRoles(roles: string[], remember: boolean) {
        this.clearRoles();
        
        const storage = remember ? localStorage : sessionStorage;
        storage.setItem('user_roles', JSON.stringify(roles));
    }
    
    /**
     * Verify if user has specified roles
     *
     * @param expectedRoles Roles to verify
     */
    public hasRoles(expectedRoles: string | string[]) {
        if (!expectedRoles) {
            return true;
        }

        const roles = this.getRoles();

        if (roles == null) {
            return false;
        }

        if (typeof expectedRoles === 'string') {
            return roles.indexOf(expectedRoles) > -1;
        } else {
            return roles.filter(role => expectedRoles.indexOf(role) > -1).length > 0;
        }
    }

    /**
     * Remove roles from brouser
     */
    public clearRoles() {
        localStorage.removeItem('user_roles');
        sessionStorage.removeItem('user_roles');
    }
}
