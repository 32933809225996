import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CrudService } from '..';
import { AppUser } from '../../dto/app-user';
import { DataListModel } from '../../models/data-list-model';
import { SetUserPasswordModel } from '../../models/set-user-password-model';

@Injectable()
export class UsersService implements CrudService<AppUser> {
  readonly apiPath = 'users/';
  constructor(
    private http: HttpClient,
  ) { }

  get(id: string): Observable<AppUser> {
    return this.http.get(this.apiPath + id) as Observable<AppUser>;
  }
  
  getMany(pageIndex: number, pageSize: number, sortField: string, sortDirection: string,
    filterString: string): Observable<DataListModel<AppUser>> {
      
      const params = new HttpParams()
      .set('page', pageIndex.toString())
      .set('pageSize', pageSize.toString())
      .set('filterString', filterString)
      .set('sortField', sortField)
      .set('sortDirection', sortDirection);
 
      return this.http.get(this.apiPath, { params }) as Observable<DataListModel<AppUser>>;
  }

  add(user: AppUser): Observable<Object> {
    return this.http.post(this.apiPath, user);
  } 

  update(id: string, user: AppUser): Observable<Object> {
    return this.http.put(this.apiPath + id, user);
  }

  delete(id: string): Observable<Object> {
    return this.http.delete(this.apiPath + id);
  }

  restore(id: number): Observable<Object> {
    return this.http.patch(this.apiPath + id, null);
  }

  deleteMany(list: number[]): Observable<Object> {
    throw new Error('Method not implemented.');
  }

  restoreMany(list: number[]): Observable<Object> {
    throw new Error('Method not implemented.');
  }

  getRoles(): Observable<string[]> {
    return this.http.get(this.apiPath + 'roles') as Observable<string[]>;
  }

  setPassword(model: SetUserPasswordModel): Observable<Object> {
    return this.http.post(this.apiPath + 'set-password', model);
  } 
}
