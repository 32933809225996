import {tap} from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpUserEvent, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable, isDevMode } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { getAuthToken } from '../helpers/storage-helper';
import { environment } from '../../environments/environment';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    url = environment.baseUrl;
    constructor(
        private router: Router) { }

    parseJwt(token): any {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.get('No-Auth') === 'True') {
            return next.handle(req.clone({ url: this.url + req.url,
              headers: req.headers
              .set('Accept', 'application/json, text/plain, */*') }));
        }

        if (getAuthToken() != null) {
          const jwtInfo = this.parseJwt(getAuthToken());

            const clonedreq = req.clone({
                url: this.url + req.url,
                headers: req.headers.set('Authorization', 'Bearer ' + getAuthToken())
                  .set('Accept', 'application/json, text/plain, */*')
            });
            return next.handle(clonedreq).pipe(
                tap(
                succ => { },
                err => {
                    if (err.status === 401 && err.error == 'must_activate_mfa') {
                      this.router.navigate(['my-account/must-activate-mfa']);
                    } else if (err.status === 401) {
                        this.router.navigate(['login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url }});
                    } else if ( err.status == 403 ) {
                      if ( jwtInfo.rol == '2fa_submission') {
                        this.router.navigate(['enter-2fa'], { queryParams: { returnUrl: this.router.routerState.snapshot.url }});
                      }
                    }
                  }
                ));
        } else {
            this.router.navigate(['login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url }});
        }
    }
}
