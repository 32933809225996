import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import { setAuthToken, clearAuthToken, getAuthToken } from '../helpers/storage-helper';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RoleService } from './role.service';
import { CredentialsModel } from '../models/credentials-model';
import { LoginResultModel } from '../models/login-result-model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  readonly apiPath = 'auth/';

  constructor(private http: HttpClient,
    private roleService: RoleService
  ) { }

  login(user: CredentialsModel): Observable<LoginResultModel> {
    return this.http.post(this.apiPath + 'login',
      {
        username: user.username,
        password: user.password
      }, { headers: new HttpHeaders({ 'No-Auth': 'True' }) }) as Observable<LoginResultModel>;
  }

  sendTotp(totp: string): Observable<LoginResultModel> {
    return this.http.post(this.apiPath + 'login',
      {
        username: getAuthToken(),
        password: 'lepass',
        totp,
      }, {  }) as Observable<LoginResultModel>;
  }

  logout() {
    clearAuthToken();
    this.roleService.clearRoles();
  }

  activateAccount(id: string, token: string) {
    const params = new HttpParams().set('token', token);
    return this.http.get(this.apiPath + `activate/${id}/`, { headers: new HttpHeaders({ 'No-Auth': 'True' }), params });
  }

  tryRecoverPassword(email: string) {
    return this.http.get(this.apiPath + 'reset-password/' + email,
    { headers: new HttpHeaders({ 'No-Auth': 'True' }) });
  }

  resetPassword(id: string, token: string, password: string) {
    return this.http.post(this.apiPath + `reset-password`,
    {id, token, password},
    { headers: new HttpHeaders({ 'No-Auth': 'True' }) });
  }
}
