
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
      private snackBar: MatSnackBar,
      private router: Router
    ) {
    }

    showMessage(message: string) {
      this.snackBar.open(message, null, {
        duration: 5000,
        panelClass: ['app-mat-snack-bar']
      });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(tap(event => {}, err => {

          if (req.headers.get('skip-error-snack')) {
            // do nothing
          } else {
            if (err instanceof HttpErrorResponse && err.status === 401) { // NotAuthorized
              // todo
            }
            if (err instanceof HttpErrorResponse && err.status === 400) { // BadRequest
              if (typeof err.error === 'string' || err.error instanceof String) {
                this.showMessage(err.error.toString());
              } else {
                const errors = Object.getOwnPropertyNames(err.error).map(x => x + ': ' + err.error[x]).join('\r\n');
                this.showMessage(errors);
              }
            } else {
                this.showMessage(err.error || err.message);
            }
          }
        }));
    }
}
