import {Injectable} from '@angular/core';
import * as moment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {MatDateFormats} from '@angular/material/core';

export const DateFormats = {
  DisplayDate: 'dd.MM.yyyy',
  DisplayDateTime: 'dd.MM.yyyy HH:mm:ss',
  MonthYearCardDate: 'MM/YY',

  MomentDisplayDate: 'DD.MM.YYYY',
  MomentForSendFormat: 'YYYY-MM-DD',
  MomentDisplayDateTime: 'DD.MM.YYYY HH:mm:ss',
  MomentDisplayDateTimeNoSeconds: 'DD.MM.YYYY HH:mm',
  MomentParseDate: 'DD.MM.YYYY HH:mm:ss ZZ',

  ServerInputDate: 'YYYY-MM-DD',
};

export function parseDateToServerDateOffset(date: Date): string {
  return moment(new Date(date), DateFormats.MomentParseDate).toISOString(true);
}

export function toISODateString(date: Date): string {
  return date ? new Date(date).toISOString() : '';
}

export function toServerDateString(date: Date): string {
  return date ? moment(new Date(date), DateFormats.MomentParseDate).format(DateFormats.ServerInputDate) : '';
}

export function toDateString(date: Date): string {
  return date ? moment(new Date(date), DateFormats.MomentParseDate).format(DateFormats.MomentDisplayDate) : '';
}

export function calculateAge(bdstr: Date): number {
  const dob = new Date(bdstr);

  const today = new Date();
  let age = today.getFullYear() - dob.getFullYear();
  const m = today.getMonth() - dob.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
    age--;
  }

  return age;
}

export function formatPhone(phone: string): string {
  if ( !phone ) {
return '';
}
  if ( phone.startsWith('+')) {
return phone;
}
  if (phone.startsWith('00')) {
phone = phone.substring(2);
}

  return '+'+phone;
}
export function formatPhoneNumeric(phone: number): string {
  if ( !phone) {
return '';
}
  return formatPhone(phone.toString());
}


export function phoneToCountry(phone: string): string {
  if ( phone.startsWith('+')) {
phone = phone.substring(1);
}
  if ( phone.startsWith('00')) {
phone = phone.substring(2);
}

  if ( phone.startsWith('49')) {
return 'DE';
}
  if ( phone.startsWith('43')) {
return 'AT';
}
  if ( phone.startsWith('41')) {
return 'CH';
}
  if ( phone.startsWith('47')) {
return 'NO';
}

  if ( phone.startsWith('39')) {
return 'IT';
}
  if ( phone.startsWith('36')) {
return 'HU';
}
  if ( phone.startsWith('33')) {
return 'FR';
}
  if ( phone.startsWith('423')) {
return 'LI';
}
  if ( phone.startsWith('45')) {
return 'DK';
}
  if ( phone.startsWith('357')) {
return 'CY';
}
  if ( phone.startsWith('420')) {
return 'CZ';
}
  if ( phone.startsWith('358')) {
return 'FI';
}
  if ( phone.startsWith('46')) {
return 'SE';
}
  if ( phone.startsWith('31')) {
return 'NL';
}
  if ( phone.startsWith('44')) {
return 'GB';
}
  if ( phone.startsWith('34')) {
return 'ES';
}
  if ( phone.startsWith('421')) {
return 'SK';
}
  if ( phone.startsWith('386')) {
return 'SI';
}
  if ( phone.startsWith('40')) {
return 'RO';
}
  if ( phone.startsWith('352')) {
return 'LU';
}
  if ( phone.startsWith('30')) {
return 'HE';
}
  if ( phone.startsWith('372')) {
return 'EE';
}
  if ( phone.startsWith('370')) {
return 'LT';
}
  if ( phone.startsWith('385')) {
return 'HR';
}
  if ( phone.startsWith('356')) {
return 'MT';
}

  return null;

}


export function toAge(dob: Date): number {
  return moment().diff(dob, 'years');

}

export function toCardExpireDateString(date: Date): string {
  return date ? moment(new Date(date), DateFormats.MomentParseDate).format(DateFormats.MonthYearCardDate) : '';
}


export function toDateTimeString(date: Date): string {
  return date ? moment(new Date(date), DateFormats.MomentParseDate).format(DateFormats.MomentDisplayDateTime) : '';
}

export function toDateTimeStringNoSeconds(date: Date): string {
  return date ? moment(new Date(date), DateFormats.MomentParseDate).format(DateFormats.MomentDisplayDateTimeNoSeconds) : '';
}


export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    dateA11yLabel: 'LL', // MMMM Do YYYY
    monthYearLabel: 'MMM YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Injectable()
export class AppDateAdapter extends MomentDateAdapter {

  getFirstDayOfWeek(): number {
    return 1;
  }

}
