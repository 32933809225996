<div fxLayout="column" fxLayoutAlign="center center" class="card">
  <div>
    <mat-icon class="icon" color="primary">sentiment_dissatisfied</mat-icon>
    <div class="header">Whoops!</div>
  </div>
  <div>
    <h1 class="caption">404</h1>
    <div>Page was not found</div>
  </div>
  <!-- <div class="return-button">
    <a href="/" mat-raised-button color="primary">Go to homepage</a>
  </div> -->
</div>