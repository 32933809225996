export const getAuthToken = () => localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token');

export const clearAuthToken = () => {
  localStorage.removeItem('auth_token');
  sessionStorage.removeItem('auth_token');
};

export const setAuthToken = (token: string, remember: boolean) => {
  clearAuthToken();

  const storage = remember ? localStorage : sessionStorage;
  storage.setItem('auth_token', token);
};


export const getConfirmationCode = () => sessionStorage.getItem('confirmation_code');

export const setConfirmationCode = (code: string) => {
  sessionStorage.setItem('confirmation_code', code);
};

export const getConfirmedPhone = () => sessionStorage.getItem('confirmation_phone');

export const setConfirmedPhone = (code: string) => {
  sessionStorage.setItem('confirmation_phone', code);
};

export const getTableFilters = (tableName: string) => JSON.parse(sessionStorage.getItem(tableName));

