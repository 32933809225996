import { Routes } from '@angular/router';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

export const appRoutes: Routes = [
  { path: '', loadChildren: () => import('app/home/home.module').then(m => m.HomeModule) },
  { path: '', loadChildren: () => import('app/authorization/authorization.module').then(m => m.AuthorizationModule) },
  { path: '', loadChildren: () => import('app/order-assistant/order-assistant.module').then(m => m.OrderAssistantModule) },
  { path: '', loadChildren: () => import('app/upload-docs/upload-docs.module').then(m => m.UploadDocsModule) },
  { path: 'wallet-onboarding', loadChildren: () => import('app/wallet-onboarding/wallet-onboarding.module').then(m => m.WalletOnboardingModule) },



  { path: 'access-denied', component: AccessDeniedComponent },
    { path: 'not-found', component: NotFoundComponent },

    { path: '**', redirectTo: 'not-found'}
];
